















































































import { mapGetters } from 'vuex';
import CommonError from 'common-modules/src/components/CommonError.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { RunCourse } from 'common-modules/src/store/interface/RunCourse';
import User from 'common-modules/src/store/User';
import CommonIcon from 'common-modules/src/components/CommonIcon.vue';
import { GradebookClassroom, GradebookCourse, GradingSchemaStep } from '@/store/interface/Gradebook';
import jwlStudentGradebookUnit from './jwlStudentGradebookUnit.vue';
import jwlStudentGradebookLegend from './jwlStudentGradebookLegend.vue';
import { ExtendedRunUnit, RunUnit } from '@/store/interface/Unit';
import { StudentSubmittedWork } from '@/store/interface/SubmittedWork';
import { RunTask } from '@/store/interface/Task';

export interface StudentGradebook {
  code: string;
  submissions: StudentSubmittedWork;
  units: ExtendedRunUnit[];
}

@Component({
  components: {
    CommonIcon,
    CommonError,
    jwlStudentGradebookLegend,
    jwlStudentGradebookUnit,
  },
  computed: mapGetters([
    'currentUser',
  ]),
})
export default class JwlStudentGradebook extends Vue {
  currentUser!: User;

  @Prop(Object)
  runCourse!: RunCourse;

  @Prop(Object)
  gradebookCourse!: GradebookCourse;

  @Prop(Object)
  activeClassroom!: GradebookClassroom;

  @Prop(Array)
  gradingSchema!: GradingSchemaStep[];

  loaded = false;
  grades: StudentGradebook|null = null;
  activeIndex = 0;
  visibleSlides = 3;
  error = null;

  @Watch('activeClassroom')
  fetchData (): void {
    this.loaded = false;

    const courseCode = this.runCourse.code;
    const concentrationCode = this.$route.params.concentration;

    this.$store.dispatch('getData', `gradebook/${concentrationCode}/course/${courseCode}/${this.activeClassroom.id}`).then((data) => {
      this.grades = data;
      this.loaded = true;

      const { grades } = this;
      const { focusTask } = this.$route.query;
      if (focusTask && grades) {
        this.$nextTick(() => {
          const index = grades.units.findIndex((unit: ExtendedRunUnit) => {
            const contained = unit.tasks.find((task: RunTask) => task.code === focusTask);
            return !!contained;
          });
          if (index >= 0) {
            if (index + this.visibleSlides < grades.units.length) {
              this.activeIndex = index;
            } else {
              this.activeIndex = grades.units.length - this.visibleSlides;
            }
          }
        });
      }
    }).catch((e) => {
      this.error = e;
    });
  }

  unitClass (unit: RunUnit): Record<string, boolean> {
    const now = new Date();
    const startDate = new Date(unit.startDate);
    const endDate = new Date(unit.endDate);
    return {
      'jwl-facilitator-gradebook__header-col--active': startDate <= now && endDate >= now,
    };
  }

  formattedDate (dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  }

  formattedTime (dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleTimeString();
  }

  goToPrevious (): void {
    if (this.activeIndex - this.visibleSlides >= 0) {
      if ((this.activeIndex % this.visibleSlides) > 0) {
        this.activeIndex -= (this.activeIndex % this.visibleSlides);
      } else {
        this.activeIndex -= this.visibleSlides;
      }
    } else {
      this.activeIndex = 0;
    }
  }

  goToNext (): void {
    if (this.grades) {
      if ((this.activeIndex + (this.visibleSlides * 2)) < this.grades.units.length) {
        this.activeIndex += this.visibleSlides;
      } else {
        this.activeIndex = this.grades.units.length - this.visibleSlides;
      }
    }
  }

  resizeHandler (): void {
    const width = window.innerWidth;
    if (width <= 800) {
      this.visibleSlides = 1;
    } else {
      this.visibleSlides = 3;
    }
  }

  get hasNextSlide (): Record<string, boolean> {
    const hasNext = this.grades && (this.activeIndex + this.visibleSlides) < this.grades.units.length;
    return {
      'jwl-student-gradebook__action--disabled': !hasNext,
    };
  }

  get hasPreviousSlide (): Record<string, boolean> {
    return {
      'jwl-student-gradebook__action--disabled': this.activeIndex <= 0,
    };
  }

  get filteredUnits (): ExtendedRunUnit[] {
    if (this.grades) {
      return this.grades.units.slice(this.activeIndex, this.activeIndex + this.visibleSlides);
    }

    return [];
  }

  get maxValues (): Record<'score' | 'attendance', number> {
    let score = 0;
    let attendance = 0;
    if (this.grades) {
      this.grades.units.forEach((unit: ExtendedRunUnit) => {
        unit.tasks.forEach((task) => {
          if (task.type === 'attendance') {
            attendance += 1;
          } else {
            score += task.maxScore;
          }
        });
      });
    }

    return {
      score,
      attendance,
    };
  }

  get maxScore (): number {
    return this.maxValues.score;
  }

  get maxAttendance (): number {
    return this.maxValues.attendance;
  }

  get studentValues (): Record<'score' | 'attendance', number> {
    let score = 0;
    let attendance = 0;
    if (this.grades) {
      const { submissions } = this.grades;
      const userId = this.currentUser.id;
      if (userId && submissions && submissions[userId]) {
        Object.values(submissions[userId]).forEach((submission) => {
          if (submission?.grade?.score && submission.grade.score > 0) {
            score += submission.grade.score;
          } else if (submission?.grade?.attended) {
            attendance += 1;
          }
        });
      }
    }

    return {
      score,
      attendance,
    };
  }

  get studentScore (): number {
    return this.studentValues.score;
  }

  get studentAttendance (): number {
    return this.studentValues.attendance;
  }

  get totalPercent (): string {
    if (this.maxScore > 0) {
      const percent = (this.studentScore / this.maxScore) * 100;
      return percent.toFixed(1);
    }
    return '0';
  }

  get attendancePercent (): string {
    if (this.maxAttendance > 0) {
      const percent = (this.studentAttendance / this.maxAttendance) * 100;
      return percent.toFixed(1);
    }
    return '0';
  }

  get letterGrade (): string|null {
    if (!this.gradingSchema) {
      return null;
    }
    const letterStep = this.gradingSchema.find((step) => step.lowerLimitRange <= Number(this.totalPercent));
    if (letterStep) {
      return letterStep.letter;
    }
    return '-';
  }

  mounted (): void {
    this.fetchData();
    window.addEventListener('resize', this.resizeHandler, {
      passive: true,
    });
    this.resizeHandler();
  }

  destroyed (): void {
    window.removeEventListener('resize', this.resizeHandler);
  }
}
