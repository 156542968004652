






































import { mapGetters } from 'vuex';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { RunCourse } from 'common-modules/src/store/interface/RunCourse';
import User from 'common-modules/src/store/User';
import { ExtendedRunUnit } from '@/store/interface/Unit';
import TaskIcon from '@/components/TaskIcon.vue';
import { RunTask } from '@/store/interface/Task';
import { StudentSubmittedWork, SubmittedWork } from '@/store/interface/SubmittedWork';

@Component({
  components: {
    TaskIcon,
  },
  computed: mapGetters([
    'currentUser',
  ]),
})
export default class JwlStudentGradebookUnit extends Vue {
  currentUser!: User;

  @Prop(Object)
  runCourse!: RunCourse;

  @Prop(Object)
  jwlUnit!: ExtendedRunUnit;

  @Prop(Object)
  studentGrades!: StudentSubmittedWork;

  useDuotone (task: RunTask): boolean {
    return !!this.grades && !!this.grades[task.id];
  }

  taskState (task: RunTask): string {
    const submitted = this.grades && this.grades[task.id];
    const baseClass = 'jwl-student-gradebook-unit__icon';

    if (submitted) {
      if (submitted.publishedAt || (submitted.grade && submitted.grade.score >= 0) || (submitted.count && submitted.count > 0)) {
        if (submitted.grade && submitted.grade.date) {
          if (task.maxScore > 0 && submitted.grade.score === 0) {
            return `${baseClass}--0-grade`;
          }
          return `${baseClass}--grade`;
        }
        return `${baseClass}--ungraded`;
      }
      return `${baseClass}--unpublished`;
    }
    return `${baseClass}--nothing`;
  }

  focusedTask (task: RunTask): Record<string, boolean> {
    const { focusTask } = this.$route.query;
    return {
      'jwl-student-gradebook-unit__icon--focused': focusTask === task.code,
    };
  }

  studentGradeScore (task: RunTask): string {
    const submitted = this.grades && this.grades[task.id];
    const gradeScore = submitted && submitted.grade && submitted.grade.score;

    return gradeScore && gradeScore >= 0
      ? gradeScore.toString()
      : '-';
  }

  showScore (task: RunTask): boolean {
    return task.type !== 'attendance' && task.maxScore > 0;
  }

  get grades (): Record<number, SubmittedWork>|null {
    const indexId = this.currentUser.id;
    if (indexId && this.studentGrades[indexId]) {
      return this.studentGrades[indexId];
    }

    return null;
  }

  get unitScores (): Record<string, Record<string, number|null>> {
    let maxOnline = 0;
    let achievedOnline = 0;
    let maxOnSite = 0;
    let achievedOnSite = 0;
    let maxAttendance = 0;
    let achievedAttendance = 0;

    this.jwlUnit.tasks.forEach((task) => {
      const isOnline = task.facilitatorResponsibility === 'online_facilitator' || task.facilitatorResponsibility === 'online-onsite_facilitator';
      const isOnSite = task.facilitatorResponsibility === 'onsite_facilitator' || task.facilitatorResponsibility === 'onsite-online_facilitator';
      const submitted = this.grades && this.grades[task.id];
      if (task.type === 'attendance') {
        maxAttendance += 1;
        if (submitted && submitted.grade && submitted.grade.attended) {
          achievedAttendance += 1;
        }
      } else if (isOnline) {
        maxOnline += task.maxScore;
        if (submitted && submitted.grade && submitted.grade.score >= 0) {
          achievedOnline += submitted.grade.score;
        }
      } else if (isOnSite) {
        maxOnSite += task.maxScore;
        if (submitted && submitted.grade && submitted?.grade?.score >= 0) {
          achievedOnSite += submitted.grade.score;
        }
      }
    });

    let onlinePercent = null;
    let onsitePercent = null;
    let totalPercent = null;
    let attendancePercent = null;
    const maxTotal = maxOnline + maxOnSite;
    const achievedTotal = achievedOnline + achievedOnSite;

    if (maxAttendance > 0) {
      const percent = (achievedAttendance / maxAttendance) * 100;
      attendancePercent = Math.round(percent);
    }
    if (maxOnline > 0) {
      const percent = (achievedOnline / maxOnline) * 100;
      onlinePercent = Math.round(percent);
    }
    if (maxOnSite > 0) {
      const percent = (achievedOnSite / maxOnSite) * 100;
      onsitePercent = Math.round(percent);
    }
    if (maxTotal > 0) {
      const percent = (achievedTotal / maxTotal) * 100;
      totalPercent = Math.round(percent);
    }

    return {
      online: {
        max: maxOnline,
        achieved: achievedOnline,
        percent: onlinePercent,
      },
      onSite: {
        max: maxOnSite,
        achieved: achievedOnSite,
        percent: onsitePercent,
      },
      attendance: {
        max: maxAttendance,
        achieved: achievedAttendance,
        percent: attendancePercent,
      },
      total: {
        max: maxTotal,
        achieved: achievedTotal,
        percent: totalPercent,
      },
    };
  }

  mounted (): void {
    const { focusTask } = this.$route.query;
    if (focusTask) {
      const index = this.jwlUnit.tasks.findIndex((task) => task.code === focusTask);
      if (index >= 0) {
        this.$el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }
}
