<template lang="pug">
  .jwl-student-legend
    .jwl-student-legend__row
      .jwl-student-legend__column
        .jwl-student-legend__surface
          .jwl-student-legend__inner-surface
        | {{$t('gradebook.legend.nothing')}}

      .jwl-student-legend__column
        .jwl-student-legend__surface.jwl-student-legend__surface--orange
          .jwl-student-legend__inner-surface-duotone
        div
          | {{$t('gradebook.legend.notPublished')}}
          br
          small
            | {{$t('gradebook.legend.publishOnTime')}}

    .jwl-student-legend__row.jwl-student-legend__row--grow
      .jwl-student-legend__column
        .jwl-student-legend__surface.jwl-student-legend__surface--light
          .jwl-student-legend__inner-surface-duotone
        div
          | {{$t('gradebook.legend.submitted')}}
          br
          small
            | {{$t('gradebook.legend.waitForGrading')}}

      .jwl-student-legend__column
        .jwl-student-legend__surface.jwl-student-legend__surface--bordered
          .jwl-student-legend__inner-surface-duotone
        | {{$t('gradebook.legend.byLocal')}}

    .jwl-student-legend__row
      .jwl-student-legend__column
        .jwl-student-legend__surface.jwl-student-legend__surface--green
        | {{$t('gradebook.legend.graded')}}

      .jwl-student-legend__column
        .jwl-student-legend__surface.jwl-student-legend__surface--red
        | {{$t('gradebook.legend.0-graded')}}
    .tbd
</template>

<script>
export default {
  name: 'jwl-student-gradebook-legend',
};
</script>

<style lang="sass">
  @import "../styles/jwlStudentLegend"
</style>
